




import Vue from "vue";
import KvkComponent from "../components/KvkComponent.vue";

export default Vue.extend({
  name: "Kvk",

  components: {
    KvkComponent,
  },
});
