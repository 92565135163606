


























































































































import Vue from "vue";
import content from "@/content.json";
import axios, { AxiosResponse } from "axios";

export default Vue.extend({
  name: "KvkComponent",
  data: () => ({
    page: content.pages.kvk,
    numRows: 0,
    error: "",
    success: "",
    files: [] as File[],
    state: {
      init: true,
      ready: false,
      loading: false,
      finished: false,
    },
    dialog: {
      upload: false,
    },
  }),
  methods: {
    downloadInstructions(){
      this.$request({
        url: `${this.$store.state.APIurl}/return_kvk/download-instructions`,
        method: "POST",
        responseType: "blob",
      })
        .then((response: any) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
            })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "KvK uitleg.pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(() => {
          this.success = "";
          this.error =
            "Het bestand kan niet worden geproduceerd. Neem contact op met de beheerder";
        })
    },
    downloadFile() {
      this.state.loading = true;
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.$store.state.user.token}`,
      };
      axios({
        url: `${this.$store.state.APIurl}/return_kvk/download-results`,
        method: "POST",
        responseType: "blob",
        headers: headers,
      })
        .then((response: any) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "kvk-twijfelgevallen.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(() => {
          this.success = "";
          this.error =
            "Het bestand kan niet worden geproduceerd. Neem contact op met de beheerder";
        })
        .finally(() => {
          this.state.loading = false;
        });
    },
    isReady() {
      this.$request(`${this.$store.state.APIurl}/return_kvk/kvk-status`)
        .then((response: any) => {
          console.log(response.data);
          this.state['finished'] = response.data.finished;
          this.state['ready'] = response.data.kvkReady && !response.data.finished;
          this.state['init'] = false;
        })
        .catch((error: any) => {
          console.log(error.response);
        });
    },
    postFile() {
      this.dialog["upload"] = false;
      this.state['loading'] = true;
      const formData = new FormData();
      for (let i = 0; i < this.$data.files.length; i++) {
        formData.append("documents", this.$data.files[i]);
      }
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.$store.state.user.token}`,
      };
      const url = `${this.$store.state.APIurl}/return_kvk/upload`;
      this.$request
        .post<FormData, AxiosResponse<any>>(url, formData, {
          headers: headers,
        })
        .then((response: any) => {
          console.log(response);
          this.$data.error = undefined;
          this.success = this.page.l_dropzone.success;
          this.state['finished'] = true;
          this.state['ready'] = false
        })
        .catch((error: any) => {
          this.success = "";
          this.error = error.response.data.detail;
        })
        .finally(() => {
          this.state['loading'] = false;
        });
      this.$data.files = [];
    },
    // required for uploading components
    clickDropZone() {
      document.getElementById("hidden-file-input")?.click();
    },
    uploadFile(e: { target: { files: any } }) {
      this.files = e.target.files;
    },
    dragFile(e: { dataTransfer: { files: any } }) {
      this.files = e.dataTransfer.files;
      if (this.files.length > 1) {
        // only allow a single file
        this.files = [this.files[0]];
      }
    },
  },
  mounted() {
    this.isReady();
  },
});
